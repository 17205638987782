import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import ReactMarkdown from "react-markdown";
// import text from "../content/areas.md";
import gavel4k from "../static/gavel_4k.jpg";
import gavelfhd from "../static/gavel_fhd.jpg";
import gavelhd from "../static/gavel_hd.jpg";
import gavel1600 from "../static/gavel_1600.jpg";
import gavel1366 from "../static/gavel_1366.jpg";
import gavel1024 from "../static/gavel_1024.jpg";
import gavel768 from "../static/gavel_768.jpg";
import gavel640 from "../static/gavel_640.jpg";
//ReactGA.pageview("/areas-actuacao");

const WorkAreas = function () {
  // const [workareastext, setworkareastext] = useState({ textcontent: null });

  useEffect(() => {
    document.title = "Áreas de Actuação | João Vilas Boas Sousa Advogados";
  }, []);
  // useEffect(() => {
  //   fetch(text)
  //     .then((response) => response.text())
  //     .then((textextracted) => {
  //       setworkareastext({ textcontent: textextracted });
  //     });
  // }, []);

  return (
    <div className="content">
      <div className="landingpagetopworkareas">
        <h1 id="workareastitleexterior">Áreas de Actuação</h1>
        <div id="worksareastext" className="pagetextcontent teachingjustify">
          <h1 id="workareastitleinterior">Áreas de Actuação</h1>
          {/* <ReactMarkdown children={workareastext.textcontent} /> */}
          {/* <ReactMarkdown>{workareastext.textcontent}</ReactMarkdown> */}
          <p>
            Áreas de Actuação com apoio de Profissionais Qualificados,
            Consultores, Mestres nas áreas do Direito e Formadores com CPP.
          </p>
          <h2>Advocacia</h2>
          <ul className="workareaslist">
            <li>
              <strong>Direito do Trabalho</strong>
              <ul>
                <li>
                  Contratos e formalidades, despedimentos individuais e
                  colectivos, impugnação de despedimentos, processos
                  disciplinares, diversos tipos de cessação de contrato de
                  trabalho, vicissitudes no decurso da relação laboral, defesa
                  em processos de contraordenação, acidentes de trabalho, gestão
                  do tempo e do local de trabalho, S.H.S.T., reclamação de
                  créditos laborais, assessoria jurídica a empresas e
                  particulares;
                </li>
              </ul>
            </li>
            <li>
              <strong>Direito Civil</strong>
              <ul>
                <li>
                  Contratos e formalidades, diversos tipos de arrendamentos,
                  despejos, compra e venda, comodato, responsabilidade civil com
                  pedido de indemnização, cobrança de dívidas, condomínios;
                </li>
              </ul>
            </li>
            <li>
              <strong>Direito Comercial</strong>
              <ul>
                <li>
                  Contratos, constituição de sociedades, dissolução de
                  sociedades, quotas sociais, alteração do pacto social,
                  insolvências singulares e colectivas, P.E.R., reclamações de
                  créditos;
                </li>
              </ul>
            </li>
            <li>
              <strong>Direito Penal</strong>
              <ul>
                <li>
                  Processo-crime, elaboração da queixa-crime, elaboração da
                  acusação particular, requerimento de abertura de instrução,
                  defesa em processo de contraordenação, pedido de indemnização
                  civil, constituição de assistente;
                </li>
              </ul>
            </li>
            <li>
              <strong>Direito Fiscal</strong>
              <ul>
                <li>
                  Reclamações graciosas, impugnação de liquidações, oposição a
                  execuções, defesa em processo de contraordenação, participação
                  de óbitos e relação de bens à A.T.;
                </li>
              </ul>
            </li>
            <li>
              <strong>Direito da Família</strong>
              <ul>
                <li>
                  Processo de casamento, convenção antenupcial, divórcio por
                  mútuo consentimento e litigioso, separação de pessoas e bens,
                  regulação das responsabilidades parentais, pensão de alimentos
                  a filhos maiores e menores, heranças, inventários, partilhas;
                </li>
              </ul>
            </li>
            <li>
              <strong>Registos e Notariado</strong>
              <ul>
                <li>
                  Registo civil, registo automóvel, registo predial, registo
                  comercial, elaboração e instrução de escrituras, realização de
                  procurações, testamentos;
                </li>
              </ul>
            </li>
            <li>
              <strong>Contencioso</strong>
              <ul>
                <li>Processo Declarativo; Executivo; Injunções;</li>
              </ul>
            </li>
            <li>
              <strong>Actos Notariais do Advogado</strong>
              <ul>
                <li>
                  Autenticação de documentos particulares, reconhecimento de
                  assinaturas, certificação de fotocópias.
                </li>
              </ul>
            </li>
          </ul>

          <h2>Formação</h2>
          <p>
            Formação específica na área do Direito do Trabalho e Gestão
            Administrativa de RH, entre outras. Mais informações no{" "}
            <Link to="/formacao#catalogo">Catálogo de Formação</Link>.
          </p>
        </div>

        <img
          srcSet={`${gavel4k} 3840w, ${gavelfhd} 1920w, ${gavelhd} 1280w, ${gavel1600} 1600w, ${gavel1366} 1366w, ${gavel1024} 1024w, ${gavel768} 768w, ${gavel640} 640w`}
          sizes="(max-width: 640px) 640px, (min-width: 3840px) 3840px, 1920px, 1280px, 1600px, 1366px, 1024px, 768px"
          src={gavelfhd}
          alt="gavel"
          className="workareaphoto"
          id="workareasimage"
        />
      </div>
    </div>
  );
};

export default WorkAreas;
